import * as React from "react";
import { Seo, Header, Card, Carousel, ProductsBanner } from "components";
import {
  colors,
  Cards,
  Section,
  Container,
  FullWidthContent,
  H2,
} from "styles";
import { graphql } from "gatsby";
import { HeatCell, BackMuscles, BloodCirculation, Target } from "assets/svgs";

const HeatTherapyPage = ({ data }) => {
  const { title, slides } = data.markdownRemark.frontmatter;
  return (
    <>
      <Seo title={title} />
      <Header
        data={data}
        title={title}
        content="<p>Heat therapy is a variant of thermotherapy. As part of physiotherapy,<br /> it is used to treat pain and non-inflammatory diseases.</p>"
        alignBottom={true}
      />
      <Section>
        <Container>
          <FullWidthContent>
            <H2 $center>Where the heat therapy works</H2>
          </FullWidthContent>
          <Carousel slides={slides} />
        </Container>
      </Section>
      <Section $bg={colors.lightGrey}>
        <Container>
          <FullWidthContent>
            <H2 $center style={{ maxWidth: "800px" }}>
              How does heat therapy work?
            </H2>
          </FullWidthContent>
          <Cards>
            <Card
              svg={<Target />}
              title="Pain relief"
              text="Heat therapy relives pain and accelerates healing right at the source"
            />
            <Card
              svg={<HeatCell />}
              title="Heat stimulation"
              text="Heat stimulates thermoreceptors, blocking pain signals to the brain"
            />
            <Card
              svg={<BloodCirculation />}
              title="Increased blood flow"
              text="Applying heat increases circulation to the injured area. Increased blood flow means more oxygen and nutrients are sent to the damaged tissue"
            />
            <Card
              svg={<BackMuscles />}
              title="Muscle relaxing"
              text="Heat helps relax damaged, tight, and sore muscles, to improve flexibility and decrease stiffness"
            />
          </Cards>
        </Container>
      </Section>
      <Section>
        <ProductsBanner />
      </Section>
    </>
  );
};

export default HeatTherapyPage;

export const query = graphql`
  query HeatTherapyQuery {
    markdownRemark(frontmatter: { title: { eq: "Heat Therapy" } }) {
      frontmatter {
        title
        slides {
          id
          step
          title
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          text
        }
      }
    }
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;
